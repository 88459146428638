import { createSignal } from 'solid-js';
import { formatGenre } from '~/data/genres';
import type { ContentDto } from '~/libs/api-client';
import { t } from '~/libs/i18n/utils';
import { link } from '~/utils';
import { ThumbnailVideoPreview } from '..';

interface VideoRecommendationItemProps {
  content: ContentDto;
  trackingProperties?: Record<string, string>;
}

export function VideoRecommendationItem(props: VideoRecommendationItemProps) {
  const [showVideoPreview, setShowVideoPreview] = createSignal(false);

  return (
    <article>
      <a
        trackable={!!props.trackingProperties}
        trackingProperties={JSON.stringify(props.trackingProperties)}
        class="flex flex-col md:flex-row gap-2 pr-1"
        href={link.toContent({
          id: props.content.id,
          kind: props.content.kind,
        })}
      >
        <ThumbnailVideoPreview
          id={props.content.id}
          class="h-48 md:h-24 w-full max-w-96 md:aspect-video md:w-[168px]
            rounded-xl shrink-0"
          thumbnails={props.content.thumbnails}
          aspectRatio={'ar16:9'}
          thumbnailPriorities={['ar16:9', 'ar4:3']}
          videoPreview={props.content.preview}
          hideSpinner
          hideVideoPreview={!showVideoPreview()}
          onMouseOver={() => setShowVideoPreview(true)}
          onMouseLeave={() => setShowVideoPreview(false)}
        />
        <div class="flex flex-col w-48">
          <span class="font-medium text-xs text-slate-400 uppercase">
            {formatGenre(props.content.genres)}
          </span>
          <h4 class="text-sm font-normal">{props.content.title}</h4>
          {props.content.categories.includes('new_releases') && (
            <div
              class="border border-primary rounded h-6 text-primary px-2
                font-bold text-2xs w-fit flex items-center mt-1"
            >
              {t('common:tags.new')}
            </div>
          )}
        </div>
      </a>
    </article>
  );
}

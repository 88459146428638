import { For, Show, createSignal } from 'solid-js';
import type { ContentKind } from '~/modules/api';
import {
  GenerationStatus,
  api,
  type SubtitlesResponseDto,
} from '~/modules/api';
import type { IngestFeedbackMessage } from '~/modules/content-validator';
import Loader from '../content-validator/Loader';
import { Button } from '../ui';

export interface SubsEditorProps {
  contentId: string;
  kind: ContentKind;
  part: string;
  title: string;
  subs: SubtitlesResponseDto[];
}

const MESSAGES_CLASSES = {
  ALERT: 'text-amber-500 m-1',
  ERROR: 'text-red-600 m-1',
  SUCCESS: 'text-green-500 m-1 text-2xl',
  INFO: 'text-grey-600 m-1',
};

export function SubsEditor(props: SubsEditorProps) {
  const [messages, setMessages] = createSignal<IngestFeedbackMessage[]>([]);
  const [subtitles, setSubtitles] = createSignal<SubtitlesResponseDto[]>(
    props.subs,
  );
  const [loading, setLoading] = createSignal(false);

  const addMessage = (message: IngestFeedbackMessage) => {
    setMessages(prev => [...prev, message]);
    const console = document.getElementById('console');
    if (console) {
      console.scrollTop = console.scrollHeight;
    }
  };

  const verifyStatus = async () => {
    setLoading(true);
    const response = await api.Subtitles.verifySubtitlesStatus({
      path: {
        ...props,
      },
    }).then(r => r.data);

    if (!response) {
      setMessages([
        {
          type: 'ERROR',
          message: 'Error verifying subtitles status!',
        },
      ]);
      setLoading(false);
      return;
    }

    setMessages([
      {
        type: 'INFO',
        message: 'General Subtitles status is: ' + response.status,
      },
    ]);

    if (response.status === GenerationStatus.COMPLETE) {
      await api.Subtitles.getSubtitlesByPart({
        path: {
          ...props,
        },
      }).then(r => {
        if (r.data) setSubtitles(r.data);
      });
    }

    setLoading(false);
  };

  const assign = async () => {
    setLoading(true);
    const response = await api.Subtitles.assignSubtitles({
      path: {
        ...props,
      },
    }).then(r => r.data);

    if (!response) {
      setMessages([
        {
          type: 'ERROR',
          message: 'Error assigning subtitles',
        },
      ]);
      setLoading(false);
      return;
    }

    setMessages([
      {
        type: 'SUCCESS',
        message: 'Subtitles Assigned successfully!',
      },
    ]);
    setLoading(false);
    await api.Subtitles.getSubtitlesByPart({
      path: {
        ...props,
      },
    }).then(r => {
      if (r.data) setSubtitles(r.data);
    });
    setLoading(false);
  };

  return (
    <main class={'flex flex-col justify-items-end mx-auto w-full'}>
      <header
        class="flex flex-col py-7 tracking-normal text-center px-4 w-1/2 mx-auto"
      >
        <h1 class="text-xl md:text-3xl font-regular leading-7 text-white">
          {props.title} Subtitles
        </h1>
        <ul id="fileList">
          <For each={messages()}>
            {error => (
              <li>
                {error.type === 'ERROR' && (
                  <input type="checkbox" class="error-checkbox" />
                )}
                <label class={MESSAGES_CLASSES[error.type]}>
                  {error.message}
                </label>
              </li>
            )}
          </For>
        </ul>
      </header>
      <div class="flex justify-center">
        <div class="flex flex-wrap justify-center gap-4 text-center">
          <For each={subtitles()}>
            {sub => (
              <div class="mb-4 w-1/3">
                <h3>
                  Subtitles for Language: <strong>{sub.language}</strong>
                </h3>
                <h3>
                  {sub.baseLanguage === sub.language
                    ? 'Transcripted on '
                    : `Translated from: `}
                  <strong>{sub.baseLanguage}</strong>
                </h3>
                <h3 class="mb-1">
                  STATUS:
                  <strong
                    class={`${
                      sub.status === 'ASSIGNED'
                        ? 'text-green-500'
                        : sub.status !== 'FAILED'
                          ? 'text-primary'
                          : 'text-red-500'
                    }`}
                  >
                    {' '}
                    {sub.status.toString()}
                  </strong>
                </h3>
                <div class="flex justify-center gap-3">
                  <Button variant={'danger'} disabled>
                    Remove
                  </Button>
                  {sub.status === 'ASSIGNED' && (
                    <Button
                      variant={'primary'}
                      href={sub.url ?? ''}
                      target="_blank"
                    >
                      Download
                    </Button>
                  )}
                  <Button variant={'secondary'} disabled>
                    Replace
                  </Button>
                </div>
              </div>
            )}
          </For>
        </div>
      </div>
      <Show when={loading()}>
        <Loader />
      </Show>
      <div class="flex flex-row gap-2 w-full mt-2">
        <Button onClick={verifyStatus} variant={'primary'} class="flex-1">
          Verify Subtitles Status
        </Button>
        <Button onClick={assign} variant={'dangerOutlined'} class="flex-1">
          Assign Subtitles
        </Button>
        <Button disabled variant={'darkBlur'} class="flex-1">
          Add Other Languages
        </Button>
      </div>
    </main>
  );
}

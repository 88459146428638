import {
  createForm,
  email,
  required,
  type SubmitHandler,
} from '@modular-forms/solid';
import { createSignal } from 'solid-js';
import { hubspotJoinNewsletter } from '~/components/actions/hubspot';
import { ConfettiStripIcon, SparkleIcon } from '~/components/icons';
import { Button, TextField } from '~/components/ui';
import { t } from '~/libs/i18n/utils';
import { showAlert } from '~/utils';

type EmailSchema = {
  email: string;
};

export function JoinNewsletterPromo() {
  return (
    <section
      class="relative w-full md:h-64 flex gap-6 md:gap-4 md:items-center
        md:justify-between flex-col md:flex-row px-6 pb-14 pt-12 md:px-14
        rounded-3xl text-black bg-gradient-to-b from-gold-200 to-gold-300"
    >
      <h2
        class="df-font-beatrice font-bold text-2xl sm:text-3xl xl:text-3xl
          min-w-48 max-w-sm md:max-w-md relative z-10"
      >
        {t('home:newsletter.title')}
      </h2>

      <EmailForm />

      <BackgroundConfetti />
    </section>
  );
}

function EmailForm() {
  const [_, { Form, Field }] = createForm<EmailSchema>();
  const [isSubmitted, setIsSubmitted] = createSignal(false);

  const handleSubmit: SubmitHandler<EmailSchema> = async values => {
    try {
      setIsSubmitted(true);

      hubspotJoinNewsletter({
        email: values.email,
        onSuccess: () =>
          showAlert(t('alerts.sentSuccess', { what: t('email') }), {
            type: 'success',
            duration: 4000,
            description: t('layout:footer.newsletter.successMsg'),
          }),
        onFailure: () =>
          showAlert(t('alerts.notSubmitted', { what: t('email') }), {
            type: 'error',
          }),
      });
    } catch (err) {
      console.error('Error submitting email to hubspot:', err);
    } finally {
      setTimeout(() => setIsSubmitted(false), 3000);
    }
  };

  return (
    <Form
      class="flex flex-col md:flex-row gap-4 items-start relative z-10"
      onSubmit={handleSubmit}
    >
      <Field
        name="email"
        type="string"
        validate={[
          required('Email is required'),
          email('Email should be correct'),
        ]}
      >
        {(field, props) => (
          <TextField
            {...props}
            {...field}
            error={field.error}
            hasError={!!field.error}
            class="md:min-w-56 lg:min-w-72 max-w-74 w-full"
            size="lg"
            placeholder={t('home:newsletter.placeholder')}
            autocomplete="email"
          />
        )}
      </Field>

      <Button
        class="min-w-32"
        type="submit"
        variant={isSubmitted() ? 'default' : 'secondaryReversed'}
        disabled={isSubmitted()}
        size="lg"
      >
        {isSubmitted() ? t('home:newsletter.done') : t('home:newsletter.cta')}
      </Button>
    </Form>
  );
}

function BackgroundConfetti() {
  return (
    <div class="absolute z-0 inset-5 md:w-11/12">
      <SparkleIcon
        class="absolute top-0 right-5 md:right-1/4 size-9 text-gold-500"
      />
      <SparkleIcon
        class="absolute bottom-14 right-1/3 md:right-20 md:bottom-48 size-4
          text-gold-500"
      />
      <SparkleIcon
        class="absolute bottom-1/3 right-6 md:bottom-12 md:right-24 size-9
          md:size-7 text-gold-500"
      />
      <ConfettiStripIcon
        class="absolute top-20 -left-2 w-14 md:w-16 md:top-10 md:left-0
          text-gold-100 rotate-12"
      />
      <ConfettiStripIcon
        class="absolute bottom-0 left-16 md:left-80 w-12 text-gold-100"
      />
      <ConfettiStripIcon
        class="absolute top-24 left-64 md:left-1/2 md:top-4 w-9 text-gold-100
          -rotate-45"
      />
    </div>
  );
}

import { For, Show, createSignal } from 'solid-js';
import { ContentKind, api } from '~/modules/api';
import type { IngestFeedbackMessage } from '~/modules/content-validator';
import { uploadVideo } from '~/modules/content-validator/ingest/upload';
import Loader from '../content-validator/Loader';
import { Button } from '../ui';

export interface VideoEditorProps {
  contentId: string;
  kind: ContentKind;
  video: string;
  videoKind: string;
  videoTitle: string;
  assetIdName: string;
}

const MESSAGES_CLASSES = {
  ALERT: 'text-amber-500 m-1',
  ERROR: 'text-red-600 m-1',
  SUCCESS: 'text-green-500 m-1 text-2xl',
  INFO: 'text-grey-600 m-1',
};

export function VideoEditor(props: VideoEditorProps) {
  const [messages, setMessages] = createSignal<IngestFeedbackMessage[]>([]);
  const [file, setFile] = createSignal<File | undefined>(undefined);
  const [loading, setLoading] = createSignal(false);

  const addMessage = (message: IngestFeedbackMessage) => {
    setMessages(prev => [...prev, message]);
    const console = document.getElementById('console');
    if (console) {
      console.scrollTop = console.scrollHeight;
    }
  };

  const onClear = () => {
    setMessages([]);
    setFile(undefined);
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const video = await uploadVideo(file()!);
      if (props.kind === ContentKind.SPIN) {
        await api.Spins.updateSpin({
          path: {
            spinId: props.contentId,
          },
          body: {
            [props.videoKind]: video.playbackId,
            [props.assetIdName]: video.assetId,
          },
        });
      } else if (props.kind === ContentKind.SHORT) {
        await api.Shorts.updateShort({
          path: {
            shortId: props.contentId,
          },
          body: {
            [props.videoKind]: video.playbackId,
            [props.assetIdName]: video.assetId,
          },
        });
      }
      /*
      await askForSubtitles(
        props.kind,
        props.contentId,
        props.videoKind,
        file()!,
        addMessage,
      );
*/
      addMessage({
        type: 'SUCCESS',
        message: 'Update Successfully!',
      });
    } catch (error) {
      addMessage({
        type: 'ERROR',
        message: 'Error Uploading video:' + error,
      });
    }
    setLoading(false);
  };

  const onSelectVideo = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*';
    input.onchange = async event => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        if (!file.type.includes('video')) {
          addMessage({
            type: 'ERROR',
            message: 'Selected file is not a video.',
          });
          return;
        }

        setFile(file);
      }
    };
    input.click();
  };

  return (
    <main class={'flex flex-col justify-items-end mx-auto w-full'}>
      <header
        class="flex flex-col py-7 tracking-normal text-center px-4 w-1/2 mx-auto"
      >
        <h1 class="text-xl md:text-3xl font-regular leading-7 text-white">
          {props.videoTitle} Video
        </h1>
        <ul id="fileList">
          <For each={messages()}>
            {error => (
              <li>
                {error.type === 'ERROR' && (
                  <input type="checkbox" class="error-checkbox" />
                )}
                <label class={MESSAGES_CLASSES[error.type]}>
                  {error.message}
                </label>
              </li>
            )}
          </For>
        </ul>
      </header>
      <div class="relative w-full aspect-video min-w-80">
        <Show when={!file()}>
          {/* @ts-expect-error: mux play is from plain html lib*/}
          <mux-player
            id="video-editor"
            min_resolution={'480p'}
            preload="none"
            playback-id={props.video}
            accent-color="#ffd075"
            class={`_flex _justify-center items-center _overflow-hidden relative
              size-full object-cover aspect-video`}
          />
        </Show>
        <Show when={file()}>
          <video
            controls
            src={URL.createObjectURL(file()!)}
            class={`_flex _justify-center items-center _overflow-hidden relative
              size-full object-cover aspect-video`}
          />
        </Show>
      </div>
      <Show when={loading()}>
        <Loader />
      </Show>
      <div class="flex flex-row gap-2 w-full mt-2">
        <Button
          onClick={() => onSelectVideo()}
          variant={'primary'}
          class="flex-1"
        >
          Select Video
        </Button>
        <Button
          onClick={onSubmit}
          variant={'primary'}
          class="flex-1"
          disabled={!file() || loading()}
        >
          Submit Changes
        </Button>
        <Button onClick={onClear} variant={'secondaryReversed'} class="flex-1">
          Clear Changes
        </Button>
      </div>
    </main>
  );
}

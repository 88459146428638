import { For, onMount } from 'solid-js';
import { t } from '~/libs/i18n/utils';
import type { ContentDto, ContentKind } from '~/modules/api';
import { isEmptyArray } from '~/utils';
import { VideoRecommendationItem } from './VideoRecommendationItem';

interface VideoRecommendationsWidgetProps {
  items: ContentDto[];
  kind: ContentKind;
}

export function VideoRecommendationsWidget(
  props: VideoRecommendationsWidgetProps,
) {
  if (isEmptyArray(props.items)) {
    return null;
  }

  let ref: HTMLElement | undefined;

  const resize = (ref: HTMLElement, height: number) => {
    ref.style.height = `${height}px`;
  };

  onMount(() => {
    const sibling = document.querySelector('#player-with-info');

    if (sibling && ref) {
      resize(ref, sibling.clientHeight);

      // Prevent Google Ads from interfering with the parent height style
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'style'
          ) {
            resize(ref, sibling.clientHeight);
          }
        });
      });

      observer.observe(ref, { attributes: true });

      // Update on sinbling height change
      const resizeObserver = new ResizeObserver(entries => {
        resize(ref, entries[0].contentRect.height);
      });

      resizeObserver.observe(sibling);
    }
  });

  const firstTwo = props.items.slice(0, 2);
  const rest = props.items.slice(2);

  return (
    <section
      ref={ref}
      id="video-recommendations"
      class="relative flex flex-col gap-4 w-full md:min-w-80 xl:min-w-90
        md:max-w-147 flex-1 h-0"
    >
      <div id="pw-iab-leaderboard" class="w-full h-auto overflow-hidden"></div>

      <div
        id="video-recommendations-title"
        class="flex items-center justify-between"
      >
        {t('player.upNext')}
        {/* Autoplay feature */}
      </div>

      <ul
        id="video-recommendations-list"
        class="flex flex-col gap-6 md:gap-3 overflow-auto no-scrollbar"
      >
        <For each={firstTwo}>
          {post => (
            <li>
              <VideoRecommendationItem
                content={post}
                trackingProperties={{
                  section: props.kind + '-right-panel-recommendations:up-next',
                }}
              />
            </li>
          )}
        </For>

        <For each={rest}>
          {post => (
            <li class="hidden md:block">
              <VideoRecommendationItem
                content={post}
                trackingProperties={{
                  section: props.kind + '-right-panel-recommendations:other',
                }}
              />
            </li>
          )}
        </For>
      </ul>

      <div
        class="absolute bottom-0 inset-x-0 h-44 bg-gradient-to-t
          from-brand-night-default z-10 invisible md:visible pointer-events-none"
      />
    </section>
  );
}
